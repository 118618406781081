import { Notifier } from '@airbrake/browser'

function buildClient() {
  if (!document.getElementById('airbrake-key')) { return }

  let airbrake = new Notifier({
    projectId: 216805,
    projectKey: document.getElementById('airbrake-key').getAttribute('data-key'),
    environment: document.getElementById('airbrake-env').getAttribute('data-env'),
    remoteConfig: false,
    performanceStats: false,
    queryStats: false,
    queueStats: false
  })

  // Make sure to update `UNSUPPORTED_BROWSER_KEYWORDS` in layouts_helper.rb to match changes made here
  var unsupported_browser_regexp = /(?:MSIE )|(?:Trident\/)|(?:Edge\/)/

  airbrake.addFilter((notice) => {
    let error = notice.errors[0]

    // Ignore webcrawlers using the discontinued PhantomJS headless browser which doesn't implement ES6 WeakMap
    if (error.type === 'ReferenceError' && error.message == "Can't find variable: WeakMap") {
      return null
    } else if (notice.context.userAgent && notice.context.userAgent.match(unsupported_browser_regexp)) {
      return null
    }

    return notice
  })

  return airbrake
}

// Provide a way for webpack code to access the Airbrake client
export const airbrake = buildClient()

// Provide a way for developers to reference it in the browser console
window.airbrake = airbrake
